import React from 'react';
import PropTypes from 'prop-types';
import Bot from '../../templates/BotPlatform/pages/Bot';
import Layout from '../../components/Layout';
import { withIntl } from '../../i18n';
const host = process.env.GATSBY_HOST;

const telegram = {
  header: {
    subTitle: 'botPlatformTelegramTitle',
    text: 'botPlatformTelegramSubTitle',
    logo: 'bot_telegram@2x.png',
    button: {
      text: 'botPlatformTelegramButtonText',
      link: `${host}/auth2/single_account?redirect_uri=${host}/&enter=signup`
    }
  },
  list: [
    {
      key: 'keyboard',
      title: 'botPlatformTelegramKeyboardTitle',
      subTitle: 'botPlatformTelegramKeyboardSubTitle',
      image: 'telegram_keyboards.png',
    },
    {
      key: 'keyboard',
      title: 'botPlatformTelegramInlineKeyboardTitle',
      subTitle: 'botPlatformTelegramInlineKeyboardSubTitle',
      image: 'telegram_inline-keyboards.png',
    },
    {
      key: 'contactCenter',
      title: 'botPlatformTelegramContactCenterTitle',
      subTitle: 'botPlatformTelegramContactCenterSubTitle',
      image: 'telegram_contact-center.png',
    },
    {
      key: 'bulkMailing',
      title: 'botPlatformTelegramBulkMailingTitle',
      subTitle: 'botPlatformTelegramBulkMailingSubTitle',
      image: 'telegram_broadcast.png',
    },
    {
      key: 'pay',
      title: 'botPlatformTelegramPayTitle',
      subTitle: 'botPlatformTelegramPaySubTitle',
      image: 'telegram_payments.png',
    },
    {
      key: 'sharingPhone',
      title: 'botPlatformTelegramSharingPhoneTitle',
      subTitle: 'botPlatformTelegramSharingPhoneSubTitle',
      image: 'telegram_share-number.png',
    },
    {
      key: 'sharingLocation',
      title: 'botPlatformTelegramSharingLocationTitle',
      subTitle: 'botPlatformTelegramSharingLocationSubTitle',
      image: 'telegram_share-location.png',
    },
    {
      key: 'richMedia',
      title: 'botPlatformTelegramRichMediaTitle',
      subTitle: 'botPlatformTelegramRichMediaSubTitle',
      image: 'telegram_rich-media.png',
    },
    {
      key: 'deepLink',
      title: 'botPlatformTelegramDeepLinkTitle',
      subTitle: 'botPlatformTelegramDeepLinkSubTitle',
      linkTitle: 'botPlatformTelegramDeepLinkLink',
      image: 'telegram_deep-link.png',
    },
  ],
  enterprise: {
    title: 'botPlatformEnterpriseTitle',
    list: [
      {
        key: 'security',
        title: 'botPlatformEnterpriseSecurityTitle',
        subTitle: 'botPlatformEnterpriseSecuritySubTitle',
        image: 'security.png',
      },
      {
        key: 'performance',
        title: 'botPlatformEnterprisePerformanceTitle',
        subTitle: 'botPlatformEnterprisePerformanceSubTitle',
        image: 'performance.png',
      },
      {
        key: 'scale',
        title: 'botPlatformEnterpriseScaleTitle',
        subTitle: 'botPlatformEnterpriseScaleSubTitle',
        image: 'scale.png',
      }
    ]
  },
  clients: {
    title: 'botPlatformTelegramClientsTitle',
    list: [
      {key: 'portmone', image: 'portmone.png'},
      {key: 'iqos', image: 'iqos.png',},
      {key: 'varus', image: 'varus.png'},
      {key: 'metro', image: 'metro.png'},
      {key: 'novaPoshta', image: 'nova-poshta.png'},
      {key: 'comfy', image: 'comfy.png'},
      {key: 'pumb', image: 'pumb.png'},
      {key: 'jooble', image: 'jooble.png'},
    ]
  },
  form: {
    type: 'Telegram',
    text: 'botPlatformTelegramFormTitle',
    phoneLeft: 'telegram_phone-left.png',
    phoneRight: 'telegram_phone-right.png',
  }
};

const TelegramPage = (props) => (
  <Layout page={'bot-platform'} {...props}>
    <Bot
      content={telegram}
      type={'telegram'}
      data={props.data}
      {...props}
    />
  </Layout>
);

TelegramPage.propTypes = {
  data: PropTypes.object
};

export default withIntl(TelegramPage);
